import React, { useState } from "react";
import { withUser, AuthAction } from "next-firebase-auth";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { XCircleIcon } from "@heroicons/react/20/solid";

import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import firebaseDbGateway from "../gateways/firebaseDbGateway";
import { checkBillingUnpaid } from "../services/website";
import Link from "next/link";

function useFormController() {
  const [isValid, setIsValid] = useState(true);

  const schema = yup
    .object({
      email: yup.string().email().required(),
      password: yup.string().required(),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onTouched",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (formData) => {
    // Get data from the form.
    const data = {
      email: formData.email,
      password: formData.password,
    };

    firebase
      .auth()
      .signInWithEmailAndPassword(data.email, data.password)
      .then(async (userCredential) => {
        // Signed in
        const uid = userCredential.user.uid;
        let orgId;
        const membership = await firebase
          .firestore()
          .collection("memberships")
          .where("uid", "==", uid)
          .orderBy("created_at")
          .get();

        // const membership = (
        //   await firebaseDbGateway.queryCollection("memberships", [
        //     ["uid", "==", uid],
        //   ])
        // ).data[0];

        console.log(`memberships.docs[0] is `);
        console.log(membership.docs[0].data());
        orgId = membership.docs[0].data().orgId;

        const billingUnpaid = await checkBillingUnpaid(orgId);
        if (billingUnpaid) {
          window.location.href = `/org/${orgId}/settings/plan-billing`;
          return;
        }
        window.location.href = `/org/${orgId}/website`;
        // ...
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log("********************* error ***************");
        console.log(error);
        setIsValid(false);
      });
  };

  return {
    register,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    isValid,
  };
}

const Login = () => {
  const { register, handleSubmit, errors, isValid } = useFormController();
  // Handles the submit event on form submit.

  return (
    <>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-12 w-auto"
            src="https://ranktivity-assets.imgix.net/assets/ranktivity-logo-dark.png?w=200&auto=compress"
            alt="Ranktivity"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Sign in to your account
          </h2>
          <p className="mt-2 text-center text-sm text-gray-600">
            Or{" "}
            <Link href="/register" shallow={true} legacyBehavior>
              <a className="font-medium text-indigo-600 hover:text-indigo-500">
                start your 30-day free trial
              </a>
            </Link>
          </p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" action="#" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    {...register("email")}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                {errors.email && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {errors.email?.message}
                  </p>
                )}
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    {...register("password")}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                {errors.password && (
                  <p className="mt-2 text-sm text-red-600" id="email-error">
                    {errors.password?.message}
                  </p>
                )}
              </div>

              <div className="flex items-center justify-between">
                <div className="text-sm">
                  <Link href="/forgot-password" shallow={true} legacyBehavior>
                    <a className="font-medium text-indigo-600 hover:text-indigo-500">
                      Forgot your password?
                    </a>
                  </Link>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-secondary-300 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-primary-300 focus:outline-none "
                >
                  Sign in
                </button>
              </div>

              {!isValid && (
                <div className="rounded-md bg-red-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <XCircleIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        Error
                      </h3>
                      <div className="mt-2 text-sm text-red-700">
                        <p>
                          There was an error logging in. email or password is
                          incorrect.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default withUser({
  whenUnauthedBeforeInit: AuthAction.RETURN_NULL,
  whenUnauthedAfterInit: AuthAction.RENDER,
})(Login);
